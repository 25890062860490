.secondaryButton {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  color: #666;
  width: fit-content;
  cursor: pointer;
  svg {
    background-color: transparent;
    fill: #fff !important;
    margin-right: 0.5rem;
  }
  div {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #666;
    white-space: nowrap;
  }

  &:hover {
    background-color: #0ab894;
    border: 1px solid #0ab894;
    color: #fff;

    svg {
      fill: #0ab894 !important;
    }

    div {
      color: #fff;
    }
  }
}

.primaryButton {
  margin-left: 0.5rem;
  background-color: #0ab894;
  border: 1px solid #0ab894;
  border-radius: 4px;
  padding: 8px 12px;
  color: #fff;
  width: fit-content;
  cursor: pointer;
  svg {
    background-color: transparent;
    fill: #0ab894 !important;
    margin-left: 0.5rem;
    margin-right: 0;
  }
  div {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #fff;
  }
  &:hover {
    background-color: #098e72;

    svg {
      fill: #098e72 !important;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btnBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.itemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.connectedText {
  font-size: 14px;
  color: #00994a;
  margin: 0;
  font-weight: 600;
}

.disconnectedText {
  font-size: 14px;
  color: #f62525;
  margin: 0;
  font-weight: 600;
}
/* Media Queries */

/* Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .secondaryButton {
    margin-bottom: 0.25rem;
    svg {
      margin-right: 0.3rem;
    }
  }
  .primaryButton {
    margin-left: 0;
    margin-top: 0.25rem;
    svg {
      margin-right: 0;
    }
  }
  .secondaryButton,
  .primaryButton {
    padding: 4px 8px;
    font-size: 10px; // Decreased font size
    white-space: nowrap;

    div {
      font-size: 10px; // Decreased font size
    }
  }
  .btnBox {
    flex-direction: column;
  }

  .flex {
    align-items: flex-start;
  }

  .itemContainer {
    flex-direction: column;
  }
}

/* Medium devices (tablets, less than 768px) */
@media (max-width: 767.98px) {
  .secondaryButton {
    margin-bottom: 0.25rem;

    svg {
      margin-right: 0.4rem;
    }
  }
  .primaryButton {
    margin-left: 0;
    margin-top: 0.25rem;

    svg {
      margin-right: 0;
    }
  }
  .secondaryButton,
  .primaryButton {
    padding: 4px 8px;
    font-size: 10px; // Slightly decreased font size

    div {
      font-size: 10px; // Slightly decreased font size
    }
  }
  .btnBox {
    flex-direction: column;
  }

  .flex {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
}

/* Large devices (desktops, less than 992px) */
@media (max-width: 991.98px) {
  .secondaryButton {
    svg {
      margin-right: 0.5rem;
    }
  }
  .primaryButton {
    svg {
      margin-right: 0;
    }
  }
  .secondaryButton,
  .primaryButton {
    padding: 4px 10px;
    font-size: 10px;

    div {
      font-size: 10px;
    }
  }

  .flex {
    justify-content: space-between;
  }
}

/* Extra large devices (large desktops, 1200px and above) */
@media (min-width: 1200px) {
  .secondaryButton {
    svg {
      margin-right: 0.5rem;
    }
  }
  .primaryButton {
    svg {
      margin-right: 0;
    }
  }
  .secondaryButton,
  .primaryButton {
    padding: 10px 14px;
    font-size: 16px;

    div {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
