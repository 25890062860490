.categoryHeader {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  background-color: #008bb2 !important;
  color: #fff !important;
  padding: 1rem;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0.75rem;
  }

  @media (max-width: 480px) {
    font-size: 10px;
    padding: 0.5rem;
  }
}
.nowrap {
  white-space: nowrap !important;
}
.noWidth {
  width: 0px !important;
}

// .scroll {
//   height: calc(100vh - 16rem) !important;
//   padding-bottom: 50px !important;
//   @media screen and (max-width: 768px) {
//     height: calc(100vh - 22rem) !important;
//   }
//   // @media screen and (max-width: $phone) {
//   //   height: calc(100vh - 15rem) !important;
//   // }
// }
.rowData {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  color: #575757;
  justify-content: flex-start;
  border: 0 !important;

  div {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    justify-content: flex-start;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0.4rem;
  }

  @media (max-width: 480px) {
    font-size: 10px;
    padding: 0.3rem;
  }
}

.headingStyle {
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 19.07px !important;
  color: black !important;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
}

.cellText {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  color: black;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
}

.headerTile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggleCell {
  display: flex;
  justify-content: space-evenly !important;
  align-items: center;
}

.editDetails {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.hide {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.deliverySchedule {
  display: flex;
  flex-direction: column;
}

.deliveryNumber {
  font-weight: bold;
  color: #333;
}

.deliveryText {
  color: #666;
  font-size: 10px !important;

  @media (max-width: 768px) {
    font-size: 8px;
  }

  @media (max-width: 480px) {
    font-size: 6px;
  }
}

.editInput {
  width: 40% !important;
  border: solid 0.5px black !important;
  height: fit-content !important;

  @media (max-width: 768px) {
    width: 60% !important;
  }

  @media (max-width: 480px) {
    width: 80% !important;
  }
}

.moreDoctype {
  color: #0ab894 !important;
  font-weight: 600 !important;
  cursor: pointer;
  margin-left: 0.5rem;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
}

.headerFix {
  position: sticky;
  top: 0;
  z-index: 1;
}
.footerFix {
  position: sticky;
  bottom: -52px;
  z-index: 1;
}
