// Container for the whole index component
.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;

  // Media query for smaller screens
  @media (max-width: 768px) {
    margin: 1rem;
  }
}

// Container for the tabs and logo
.contentContainer {
  border-radius: 6px;
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 2rem;
  width: 75%;
  height: 75vh; // Fixed height
  flex-direction: column;
  overflow: hidden;

  // Media query for tablet and mobile screens
  @media (max-width: 768px) {
    width: 90%;
    padding: 1.5rem;
    height: 65vh;
  }

  @media (max-width: 480px) {
    padding: 1rem;
    height: 60vh;
  }
}

// Container specifically for the tabs to enable scrolling
.tabsContainer {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 60px); // Adjusts height to allow space for logo

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;

    &:hover {
      background: #555;
    }
  }

  // Media query for tablet and mobile screens
  @media (max-width: 768px) {
    height: calc(100% - 50px);
  }
}

// Styling for individual tab containers
.tabContainer {
  width: 60%;
  cursor: pointer;
  padding: 1rem;
  font-weight: 600;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: #0ab894;
    color: white;
  }

  // Media query for smaller screens
  @media (max-width: 768px) {
    width: 80%;
    padding: 0.8rem;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 0.6rem;
  }
}
.active {
  pointer-events: none;
  cursor: not-allowed;
}

// Styling for the sign-in link
.signInLink {
  padding: 1rem;
  display: flex;
  justify-content: center;
  text-decoration: underline;
  font-weight: 400;
  cursor: pointer;

  // Media query for smaller screens
  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.8rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
    padding: 0.6rem;
  }
}

.signInText {
  padding: 1rem;
  display: flex;
  justify-content: center;
  text-decoration: underline;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: blue;
  }
}
