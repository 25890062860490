.rowData {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  color: #575757;
  justify-content: flex-start;
  border: none !important;

  div,
  a {
    font-family: "Open Sans";
    font-size: inherit; // Inherit font size from .rowData for consistency
    font-weight: 400;
    line-height: 19.07px;
    justify-content: flex-start;
    color: #575757;
    text-decoration: none; // To remove underline from links
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0.4rem;
  }

  @media (max-width: 680px), (max-width: 480px) {
    font-size: 10px;
    padding: 0.3rem;
  }
}
.headerFix {
  position: sticky;
  top: 0;
  z-index: 1;
}

.totalData {
  padding: 0.5rem;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  color: #fff !important ;
  justify-content: flex-start;
  border: none !important;

  div {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    justify-content: flex-start;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0.4rem;
  }

  @media (max-width: 480px) {
    font-size: 10px;
    padding: 0.3rem;
  }
}

.headingStyle {
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 19.07px !important;
  color: black !important;
  border: none !important;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
}
//invoice paymets report

.agingDetailReport {
  display: flex;
  flex-direction: column;
  // overflow-y: scroll;
  // height: 500px;
}
.title {
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 14px;
  background-color: #008bb2 !important;
  width: 101% !important;
}
.agingDetailReport thead,
.agingDetailReport tr {
  display: flex;
  flex-direction: row;
  width: 101%;
  /* margin-bottom: 0.8em; */
}

.agingDetailReport thead {
  font-size: 16px;
  background-color: #d6d6d6;
  font-family: "Open Sans", sans-serif;
}

.agingDetailReport .rowTotal:nth-last-child(1) {
  font-family: "Open Sans", sans-serif;
  background-color: lightgray;
}

.agingDetailReport th,
.agingDetailReport td {
  width: 16%;
  text-align: left;
  word-break: break-word;
  font-size: 14px;
  @media (max-width: 680px), (max-width: 480px) {
    font-size: 10px;
    padding: 0.5rem !important;
  }
}

.agingDetailReport th:nth-child(3),
.agingDetailReport td:nth-child(3) {
  width: 33%;
}

.agingDetailReport th:nth-child(4),
.agingDetailReport td:nth-child(4) {
  width: 15%;
  margin-right: auto;
}

.agingDetailReport .totalSubHeader {
  background-color: lightgrey;
  font-weight: 600;
  color: black;
}

.agingDetailReport .totalSubHeader:nth-child(1) {
  width: 50%;
  margin-right: auto;
}

.agingDetailReport .totalSubHeader:nth-child(2) {
  width: 12.5%;
}

.agingDetailReport .totalSubHeader:nth-child(3) {
  width: 24%;
}

.agingDetailReport .innerTitle {
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 14px;
  background-color: #008bb2 !important;
  width: 101% !important;
}

.agingDetailReport .overAllTotal {
  border-top: 0px !important;
}

.agingDetailReport .emptyRow {
  display: none;
}
