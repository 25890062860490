.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.selectContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.rightContainer {
  display: flex;
  gap: 1rem;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }
}

.columnFlex {
  display: flex;
  flex-direction: column;
}

.searchBtn {
  margin-top: 1rem !important;
  height: 35px !important;
}
.errorMsg {
  display: flex;
  justify-content: flex-end;
  color: red;
  font-weight: 400;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}
