.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 500px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalBody {
  margin-bottom: 20px;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
}

.formGroup {
  margin-bottom: 15px;
}

.passwordWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

input {
  width: 100%;
  padding: 8px 40px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.inputError {
  border-color: red;
}

.errorText {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.eyeButton {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.primaryButton {
  background-color: #2fb5ff;
  border: 1px solid #2fb5ff;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.secondaryButton {
  background-color: #6c757d;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
