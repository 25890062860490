.tabContainer {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap; // Allow wrapping on smaller screens
}

.tabSmall {
  padding: 10px 7px;
  border: 1px solid #ccc;
  border-radius: 0;
  cursor: pointer;
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.07px;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  &:last-child {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  &.active {
    background-color: #00bfa5;
    color: white;
  }
}

@media (max-width: 768px) {
  .tabSmall {
    font-size: 12px;
    padding: 8px 16px;
  }
}

@media (max-width: 576px) {
  .tabContainer {
    flex-direction: column;
  }

  .tabSmall {
    font-size: 10px;
    padding: 6px 12px;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .tabSmall:last-child {
    margin-bottom: 0;
  }

  .tabSmall.active {
    border-radius: 5px;
  }
}
.tab {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 0;
  cursor: pointer;
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.07px;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  &:last-child {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  &.active {
    background-color: #00bfa5;
    color: white;
  }
}

@media (max-width: 768px) {
  .tab {
    font-size: 12px;
    padding: 8px 16px;
  }
}

@media (max-width: 576px) {
  .tabContainer {
    flex-direction: column;
  }

  .tab {
    font-size: 10px;
    padding: 6px 12px;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .tab:last-child {
    margin-bottom: 0;
  }

  .tab.active {
    border-radius: 5px;
  }
}
