.grouped-select-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  min-width: 350px;
}

.grouped-select-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
}
.input {
  border: 2px solid lightgrey !important;
  border-radius: 4px !important;
}
.grouped-select-search {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s;
}

.grouped-select-search:focus {
  border-color: #007bff;
}

.grouped-select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000 !important;
  margin-top: 8px;
  box-sizing: border-box;
}

.group {
  padding: 8px;
}
.spinner {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.group-label {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
  background-color: #5fd0b9;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.option {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 14px;
  cursor: pointer;
  pointer-events: auto;
  transition: background-color 0.3s;
}

.option:hover {
  background-color: #f1f1f1;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.no-options {
  padding: 8px;
  font-size: 14px;
  color: #888;
}
.group-label {
  cursor: pointer;
  font-weight: bold;
}

.options-container {
  margin-top: 8px;
}

.group .options-container {
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}

.group .collapsed .options-container {
  max-height: 0;
}

.highlight {
  background-color: #98d8eb;
  font-weight: bold;
}

.grouped-select-dropdown::-webkit-scrollbar {
  width: 8px; /* Width of the vertical scrollbar */
}

.grouped-select-dropdown::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
  border-radius: 10px;
}

.grouped-select-dropdown::-webkit-scrollbar-thumb {
  background-color: #888; /* Thumb color */
  border-radius: 10px;
}

.grouped-select-dropdown::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}
.address {
  color: grey;
  font-size: 12px;
}

.genText {
  color: black;
  font-weight: 500;
}
