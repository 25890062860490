// ErrorSection.module.scss

$error-background: #e7e7e7;
$error-color: red;
$warning-color: #f3c00b;
$error-padding: 12px;
$error-margin-top: 16px;
$error-border-radius: 8px;
$error-font-family: "Open Sans";
$error-font-size: 12px;
$error-line-height: 16px;

.errorSection {
  background-color: $error-background;
  padding: $error-padding;
  margin-top: $error-margin-top;
  border-radius: $error-border-radius;

  .header {
    display: flex;
    align-items: center;

    .icon {
      // Add styles for the icon container if needed
    }

    .error {
      font-family: $error-font-family;
      font-size: $error-font-size;
      color: $error-color;
      font-weight: 600;
      margin-left: 8px;
      line-height: $error-line-height;
    }
    .warning {
      font-family: $error-font-family;
      font-size: $error-font-size;
      color: $warning-color;
      font-weight: 600;
      margin-left: 8px;
      line-height: $error-line-height;
    }
  }

  .description {
    font-family: $error-font-family;
    font-size: $error-font-size;
    font-weight: 400;
    line-height: $error-line-height;
  }

  .errorList {
    list-style: none;
    padding: 0;

    li {
      &:before {
        content: "•";
        margin-right: 8px;
      }
    }
  }
}

.import-invoice-data {
  width: 100%;
  height: 80vh;
  border-radius: 16px;
  padding: 16px;
  overflow-x: auto;

  .checkbox-container {
    display: flex;
    align-items: center;
  }
  .checkbox {
    -webkit-appearance: "checkbox";
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
  }

  .info-icon {
    width: 20px;
    height: 20px;
    color: #0ab894;
  }

  .upload-text {
    display: flex;
    justify-content: space-between;
  }

  .file-name {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
  }

  .file-info-container {
    display: flex;
    width: 100%;
  }

  .back-to-accounts {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
    width: fit-content;
  }
  .drag {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .normal-text {
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .uploadbtn {
    width: max-content;
    height: 42px;
    padding: 12px 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #008bb2;
    color: #fff;
    cursor: pointer;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }

  .button {
    width: max-content;
    height: 42px;
    padding: 12px 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #008bb2;
    color: #fff;
    cursor: pointer;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }

  .disabled-button {
    width: max-content;
    height: 42px;
    padding: 12px 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #a8a4ab;
    pointer-events: none;
    color: #fff;
    cursor: pointer;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }
  .fileSection {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #e7e7e7;
    padding: 8px;
    border-radius: 8px;
  }

  .content {
    display: flex;
    justify-content: center;

    .upload-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 50%;

      .title {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .title-text {
          font-family: "Open Sans";
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          text-align: left;
        }
      }

      .upload-box {
        border-radius: 8px;
        border: 1px dashed #008bb24d;
        gap: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4% 0;

        .upload-icon {
          width: 15%;
          height: 100%;
        }

        .upload-text {
          font-family: "Open Sans";
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
        }
      }

      .instructions {
        margin-top: 2%;
        padding: 14px 15px;
        gap: 12px;
        border-radius: 8px;
        border: 1px solid #e7e7e7;

        .title-bold {
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
        }

        .instructions-link {
          font-family: "Open Sans";
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          white-space: nowrap;
          display: flex;
          color: black;

          .import-guide {
            color: #008bb2;
            font-family: "Open Sans";
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            margin-left: 1%;
            cursor: pointer;
          }
        }

        ul {
          li {
            font-family: "Open Sans";
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            display: flex;
            color: black;
            // white-space: nowrap;
            align-items: center;

            span {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .import-invoice-data .content .upload-container {
    width: 60%;
  }
}

@media (max-width: 992px) {
  .import-invoice-data .content .upload-container {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .import-invoice-data .content .upload-container {
    width: 90%;
  }

  .import-invoice-data .button,
  .import-invoice-data .disabled-button {
    width: 61%;
    text-align: center;
  }

  .import-invoice-data .uploadbtn {
    width: 61%;
    text-align: center;
    padding: 2px;
    font-size: 12px;
  }

  .import-invoice-data .instructions {
    height: 55vh;
    overflow: scroll;
  }
}

@media (max-width: 576px) {
  .import-invoice-data {
    height: auto;
    overflow: scroll;
    padding: 8px;
  }

  .import-invoice-data .instructions-link {
    display: contents !important;
  }

  .import-invoice-data .fileSection {
    flex-direction: row;
    align-items: center;
  }

  .import-invoice-data .content .upload-container {
    width: 100%;
  }

  .import-invoice-data .drag {
    border-radius: 0;
  }
}
